<template>
    <div class="row spacer-navigation">
        <div class="col-12">
            <h1 class="scale-6 spacer-80-60" :id="anchorTitle">
                {{ title }}
            </h1>
            <template v-if="courses?.length">
                <h2 class="scale-3 scale-3--bold-uppercase spacer-30">
                    {{ t('course.overview.courses') }}
                </h2>
                <div class="spacer-180-120 courses">
                    <CourseTeaser v-for="item in courses" :teaser="item" />
                </div>
            </template>
        </div>
    </div>
    <div class="row justify-content-center spacer-footer">
        <div class="col-12 col-800-10 col-1200-8">
            <h2 class="scale-3 scale-3--bold-uppercase spacer-30">
                {{ t('course.overview.events') }}
            </h2>
            <ul v-if="events?.summaries?.length" class="events">
                <li v-for="item in events?.summaries">
                    <EventSummary :event="item" />
                </li>
            </ul>
            <div
                v-if="!events?.upcoming"
                class="scale-3 scale-3--bold no-upcoming"
                :class="{
                    'no-upcoming--past-events': events?.summaries?.length,
                }"
            >
                {{ t('course.overview.upcoming') }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const { t } = useI18n();

const title = computed(() => page?.value?.title);
const courses = computed(() => page?.value?.courses);
const events = computed(() => page?.value?.events);

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.courses {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: clamp(50px, 0.69vw + 47.78px, 60px);
    row-gap: clamp(50px, 0.69vw + 47.78px, 60px);

    @include media-breakpoint-down(640) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.events {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        &:not(:last-child) {
            margin-bottom: clamp(50px, 0.69vw + 47.78px, 60px);
        }
    }
}

.no-upcoming {
    padding: 40px;
    text-align: center;
    width: 100%;
    border: 1px solid $color-dark-disabled;
    border-radius: 12px;

    &--past-events {
        margin-top: clamp(50px, 0.69vw + 47.78px, 60px);
    }
}
</style>
