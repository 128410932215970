<template>
    <div class="event">
        <div
            v-if="event?.date"
            class="equal-small-2 equal-small-2--bold event__date"
        >
            {{ event.date }}
        </div>
        <div v-if="event?.title" class="scale-1 scale-1--bold event__title">
            {{ event.title }}
        </div>
        <div v-if="event?.description" class="equal-base">
            {{ event.description }}
        </div>
        <NuxtLink
            v-if="event?.to"
            :to="event?.to"
            class="link link--animated event__link"
            :class="{
                'link--external': getTarget(event?.to) == '_blank',
            }"
            :target="getTarget(event?.to)"
        >
            {{ t('event.summary.moreInfos') }}
        </NuxtLink>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { getTarget } from '~/utils/externalLink';

const { t } = useI18n();

const props = defineProps({
    event: {
        type: Object,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.event {
    &__date {
        color: $color-disabled;
        margin-bottom: 5px;
    }

    &__title {
        margin-bottom: 18px;
    }

    &__link {
        margin-top: 15px;
    }
}
</style>
